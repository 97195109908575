<template>
	<section class="hero d-flex align-items-center text-white text-center">
    <div class="container">
      <h1><span class="highlight">Schedule</span></h1>
    </div>
  </section>

  <section class="py-3">
    <div class="container">
      <div class="row justify-content-center">
        <table class="table table-schedule table-striped table-dark" v-if="events.length">
          <thead>
            <tr>
              <th scope="col">Date</th>
              <th scope="col">Event</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="event in events" :key="event._id">
              <th scope="row">{{ formatEventDate(event.eventStartDate, event.eventEndDate) }}</th>
              <td>{{ event.eventName }}</td>
            </tr>
          </tbody>
        </table>
        <div v-else>
          <p>No events scheduled at this time.</p>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'SiteSchedule',
  data() {
    return {
      events: []
    };
  },
  async created() {
    await this.loadEvents();
  },
  methods: {
    async loadEvents() {
      try {
        // Fetch calendar settings
        const settingsResponse = await fetch('/api/events/settings');
        const settings = await settingsResponse.json();

        let eventsResponse;
        if (settings.autoDisplay) {
          // Fetch a limited number of upcoming events
          eventsResponse = await fetch(`/api/events/upcoming?limit=${settings.numUpcomingEvents}`);
        } else {
          // Fetch events where showEvent is true
          eventsResponse = await fetch('/api/events/showSelected');
        }

        if (eventsResponse.ok) {
          this.events = await eventsResponse.json();
        } else {
          console.error('Failed to load events:', await eventsResponse.text());
        }
      } catch (error) {
        console.error('Error fetching events:', error);
      }
    },
    formatEventDate(startDate, endDate) {
      const options = { month: 'short', day: 'numeric' };
      const start = new Date(startDate).toLocaleDateString('en-US', options);
      const end = new Date(endDate).toLocaleDateString('en-US', options);

      if (start === end) {
        return start;
      } else {
        return `${start} - ${end}`;
      }
    }
  }
};
</script>
