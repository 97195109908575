const fetchServices = async () => {
  const response = await fetch('/api/servicesList');
  if (!response.ok) {
    throw new Error('Failed to fetch services');
  }
  const data = await response.json();
  return data || []; // Fallback to an empty array if data is null
};

const fetchServicesByCategory = async (category) => {
  const response = await fetch(`/api/servicesList/category/${category}`);
  if (!response.ok) {
    throw new Error(`Failed to fetch services for category: ${category}`);
  }
  return response.json();
};

const fetchServiceById = async (id) => {
  const response = await fetch(`/api/servicesList/${id}`);
  if (!response.ok) {
    throw new Error(`Failed to fetch service with id: ${id}`);
  }
  return response.json();
};

const createService = async (service) => {
  const token = localStorage.getItem('token');
  const response = await fetch('/api/servicesList', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': token ? `Bearer ${token}` : ''
    },
    body: JSON.stringify(service)
  });
  if (!response.ok) {
    throw new Error('Failed to create service');
  }
  return response.json();
};

const updateService = async (id, service) => {
  const token = localStorage.getItem('token');
  const response = await fetch(`/api/servicesList/${id}`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': token ? `Bearer ${token}` : ''
    },
    body: JSON.stringify(service)
  });
  if (!response.ok) {
    throw new Error('Failed to update service');
  }
  return response.json();
};

const deleteService = async (id) => {
  const token = localStorage.getItem('token');
  const response = await fetch(`/api/servicesList/${id}`, {
    method: 'DELETE',
    headers: {
      'Authorization': token ? `Bearer ${token}` : ''
    }
  });
  if (!response.ok) {
    throw new Error('Failed to delete service');
  }
  return response.json();
};

export {
  fetchServices,
  fetchServicesByCategory,
  fetchServiceById,
  createService,
  updateService,
  deleteService
};
