<template>
  <div class="container-fluid">
    <div class="d-sm-flex align-items-center justify-content-between mb-4">
      <h1 class="h3 mb-0 text-gray-800">Home Page</h1>
    </div>

    <div class="row">
      <div class="col-lg-8">
        <div class="card shadow mb-4">
          <a href="#collapseHomepageQuote" class="d-block card-header py-3" data-toggle="collapse" role="button" aria-expanded="true" aria-controls="collapseHomepageQuote">
            <h6 class="m-0 font-weight-bold text-primary">Homepage Quote</h6>
          </a>
          <div class="card-body collapse" id="collapseHomepageQuote">
            <label>Edit Quote</label>
            <form @submit.prevent="saveContent">
              <Editor
                v-model="pageData.homepageQuote" 
                :api-key="tinymceApiKey" 
                :init="{
                  height: 350,
                  menubar: false,
                  plugins: 'link code',
                  toolbar: 'undo redo | bold italic forecolor | alignleft aligncenter alignright alignjustify | removeformat | link code',
                  color_map_foreground: [
                    '000000', 'Black',
                    'FFFFFF', 'White',
                    'e2b06e', 'Gold',
                  ],
                  content_style: 'body { background-color: #878e95; }',
                }"
              />
              <div class="form-group">
                <div class="text-right">
                  <br />
                  <button type="submit" class="btn btn-success btn-icon-split">
                    <span class="icon text-white-50">
                      <i class="fas fa-check"></i>
                    </span>
                    <span class="text">Save</span>
                  </button>
                  <br />
                  <span v-if="contentSaved" class="badge badge-pill badge-success" style="font-size: 0.8rem;">Changes Saved</span>
                  <span v-if="contentError" class="badge badge-pill badge-danger" style="font-size: 0.8rem;">Error Saving</span>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-8">
        <div class="card shadow mb-4">
          <a href="#collapseSocialMedia" class="d-block card-header py-3" data-toggle="collapse" role="button" aria-expanded="true" aria-controls="collapseSocialMedia">
            <h6 class="m-0 font-weight-bold text-primary">Social Media Links</h6>
          </a>
          <div class="card-body collapse" id="collapseSocialMedia">
            <div class="row">
              <div class="col-lg-12">
                <form @submit.prevent="saveContent">
                  <div class="form-group">
                    <label for="instagram">Instagram</label>
                    <input v-model="pageData.instagram" id="instagram" class="form-control" type="text" placeholder="Instagram" />
                  </div>
                  <div class="form-group">
                    <label for="facebook">Facebook</label>
                    <input v-model="pageData.facebook" id="facebook" class="form-control" type="text" placeholder="Facebook" />
                  </div>
                  <div class="form-group">
                    <div class="text-right">
                      <br />
                      <button type="submit" class="btn btn-success btn-icon-split">
                        <span class="icon text-white-50">
                          <i class="fas fa-check"></i>
                        </span>
                        <span class="text">Save</span>
                      </button>
                      <br />
                      <span v-if="contentSaved" class="badge badge-pill badge-success" style="font-size: 0.8rem;">Changes Saved</span>
                      <span v-if="contentError" class="badge badge-pill badge-danger" style="font-size: 0.8rem;">Error Saving</span>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Editor from '@tinymce/tinymce-vue';

export default {
  name: 'AdminPagesHome',
  components: {
    Editor
  },
  data() {
    return {
      pageData: '',
      tinymceApiKey: process.env.VUE_APP_TINYMCE_API_KEY,
      contentSaved: false,
      contentError: false,
    };
  },
  created() {
    this.fetchContent();
    //this.populateData()
  },
  methods: {
    async fetchContent() {
      try {
        const response = await fetch('/api/content/page_home');
        const data = await response.json();
        
        if(data.content) {
          
          this.pageData = JSON.parse(data.content);
        }
      } catch (error) {
        console.error('Error fetching content:', error);
      }
    },
    async saveContent() {
      const token = localStorage.getItem('token');
      try {
        const response = await fetch('/api/content/page_home', {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': token ? `Bearer ${token}` : '',
          },
          body: JSON.stringify({ content: this.pageData })
        });

        if (response.ok) {
          //alert('Content saved successfully!');
          this.contentSaved = true;
          this.contentError = false;
          
          setTimeout(() => {
            this.contentSaved = false;
          }, 5000);
        } else {
          const errorData = await response.json();
          console.log(`Error saving content: ${errorData.error}`);
          
          this.contentSaved = false;
          this.contentError = true;
          
          setTimeout(() => {
            this.contentError = false;
          }, 5000);
        }
      } catch (error) {
        console.error('Error saving content:', error);
        console.log('An error occurred while saving the content.');
        this.contentSaved = false;
        this.contentError = true;
        
        setTimeout(() => {
          this.contentError = false;
        }, 5000);
      }
    }
  }
}
</script>
