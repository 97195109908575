<template>
  <div class="container-fluid">
    <div class="d-sm-flex align-items-center justify-content-between mb-4">
      <h1 class="h3 mb-0 text-gray-800">User Administration</h1>
    </div>

    <div class="row">
      <div class="col-lg-8">
        <div class="card shadow mb-4">
          <div class="card-header py-3">
            <h6 class="m-0 font-weight-bold text-primary">User List</h6>
          </div>
          <div class="card-body">
            <div class="table-responsive">
              <table class="table table-bordered" id="dataTable" width="100%" cellspacing="0">
                <thead>
                  <tr>
                    <th>First Name</th>
                    <th>Last Name</th>
                    <th>Email</th>
                    <th>Is Administrator</th>
                    <th>Edit</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="user in users" :key="user._id">
                    <td>{{ user.firstName }}</td>
                    <td>{{ user.lastName }}</td>
                    <td>{{ user.email }}</td>
                    <td>{{ user.isAdministrator ? 'Yes' : 'No' }}</td>
                    <td>
                      <!-- Edit link can be added here -->
                      <!-- <button class="btn btn-primary">Edit</button> &nbsp;|&nbsp; -->
                      <a @click="confirmDelete(user)" class="btn btn-danger btn-circle btn-sm">
                        <i class="fas fa-trash"></i>
                      </a>
                    </td>
                  </tr>
                </tbody>
              </table>
              <p v-if="users.length === 0">No users registered.</p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-lg-8">
        <div class="card shadow mb-4">
          <a href="#collapseUserRegister" class="d-block card-header py-3" data-toggle="collapse" role="button" aria-expanded="true" aria-controls="collapseUserRegister">
            <h6 class="m-0 font-weight-bold text-primary">User Registration</h6>
          </a>
          <div class="collapse" id="collapseUserRegister">
            <div class="card-body">
              <form @submit.prevent="register">
                <div class="row">
                  <div class="col-lg-6">
                    <div class="form-group">
                      <label for="firstName">First Name *</label>
                      <input v-model="firstName" id="firstName" class="form-control" type="text" placeholder="First Name" required />
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <div class="form-group">
                      <label for="lastName">Last Name *</label>
                      <input v-model="lastName" id="lastName" class="form-control" type="text" placeholder="Last Name" required />
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-lg-6">
                    <div class="form-group">
                      <label for="email">Email *</label>
                      <input v-model="email" id="email" class="form-control" type="email" placeholder="Email" required />
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <div class="form-group">
                      <label for="password">Password *</label>
                      <input v-model="password" id="password" class="form-control" type="password" placeholder="Password" required />
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-lg-6">
                    <div class="form-group form-check">
                      <input type="checkbox" class="form-check-input" id="isAdmin" v-model="isAdministrator">
                      <label class="form-check-label" for="isAdmin">Administrator User</label>
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <div class="float-sm-right">
                      <button type="submit" class="btn btn-success btn-icon-split">
                        <span class="icon text-white-50">
                          <i class="fas fa-check"></i>
                        </span>
                        <span class="text">Add New User</span>
                      </button>
                    </div>
                  </div>
                </div>
                <p v-if="errorMessage">{{ errorMessage }}</p>
                <p v-if="successMessage">{{ successMessage }}</p>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <AdminModal :onSubmit="deleteUser">
    <template v-slot:header>
      Confirm Deletion
    </template>
    <template v-slot:body>
      Are you sure you want to delete this user?
    </template>
    <template v-slot:submitButton>
      Delete
    </template>
  </AdminModal>
</template>

<script>
import AdminModal from '@/components/admin/AdminModal.vue';
import { fetchUsers } from '@/utils/users';

export default {
  name: 'AdminUsers',
  components: {
    AdminModal,
  },
  data() {
    return {
      firstName: '',
      lastName: '',
      email: '',
      password: '',
      isAdministrator: false,
      errorMessage: '',
      successMessage: '',
      users: [], // Array to hold user data
      selectedUser: null // User selected for deletion
    };
  },
  created() {
    this.loadUsers(); // Fetch users when component is created
  },
  methods: {
    async register() {
      try {
        const token = localStorage.getItem('token');
        const response = await fetch('/api/auth/register', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': token ? `Bearer ${token}` : '',
          },
          body: JSON.stringify({
            firstName: this.firstName,
            lastName: this.lastName,
            email: this.email,
            password: this.password,
            isAdministrator: this.isAdministrator
          })
        });

        if (!response.ok) {
          const errorData = await response.json();
          this.errorMessage = errorData.error || 'Registration failed';
          return;
        }

        // Clear the form inputs
        this.firstName = '';
        this.lastName = '';
        this.email = '';
        this.password = '';
        this.isAdministrator = false;

        this.successMessage = 'Registration successful!';
        this.errorMessage = '';

        // Fetch the updated list of users
        this.loadUsers();

      } catch (error) {
        this.errorMessage = 'An error occurred during registration';
        console.error('Registration error:', error);
      }
    },
    async loadUsers() {
      this.users = await fetchUsers('');
    },
    confirmDelete(user) {
      this.selectedUser = user;
      // eslint-disable-next-line no-undef
      $('#customModal').modal('show'); // Trigger the modal
    },
    async deleteUser() {
      if (this.selectedUser) {
        try {
          const token = localStorage.getItem('token');
          const response = await fetch(`/api/auth/users/${this.selectedUser._id}`, {
            method: 'DELETE',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': token ? `Bearer ${token}` : '',
            }
          });

          if (!response.ok) {
            const errorData = await response.json();
            console.error('Error deleting user:', errorData.error);
            return;
          }

          // Remove the deleted user from the users list
          this.users = this.users.filter(user => user._id !== this.selectedUser._id);

        } catch (error) {
          console.error('Error deleting user:', error);
        }
      }
    }
  }
}
</script>