<template>
  <div class="container-fluid">
    <div class="d-sm-flex align-items-center justify-content-between mb-4">
      <h1 class="h3 mb-0 text-gray-800">Services List Page</h1>
    </div>

    <div class="row">
      <div class="col-lg-8">
        <div class="card shadow mb-4">
          <a href="#collapseServicesList" class="d-block card-header py-3" data-toggle="collapse" role="button" aria-expanded="true" aria-controls="collapseServicesList">
            <h6 class="m-0 font-weight-bold text-primary">Services List</h6>
          </a>
          <div class="card-body collapse services-list" id="collapseServicesList">
            <form @submit.prevent="saveServices">
              <div v-for="(chunk, chunkIndex) in chunkedServiceList" :key="chunkIndex" class="row border-bottom-primary">
                <div class="col-lg-4" v-for="service in chunk" :key="service._id">
                  <div class="form-group">
                    <label :for="'serviceTitle' + service._id">Title</label>
                    <input v-model="service.title" :id="'serviceTitle' + service._id" class="form-control" type="text" placeholder="Title" />
                  </div>
                  <div class="form-group">
                    <label :for="'servicePrice' + service._id">Price</label>
                    <input v-model.number="service.price" :id="'servicePrice' + service._id" class="form-control" type="number" placeholder="Price" step="0.01" />
                  </div>
                  <div class="form-group">
                    <label :for="'serviceTime' + service._id">Time</label>
                    <input v-model="service.time" :id="'serviceTime' + service._id" class="form-control" type="text" placeholder="Time" />
                  </div>
                  <div class="form-group">
                    <label :for="'serviceNote' + service._id">Note</label>
                    <input v-model="service.note" :id="'serviceNote' + service._id" class="form-control" type="text" placeholder="Note" />
                  </div>
                  <div class="form-group">
                    <label :for="'serviceCategory' + service._id">Category</label>
                    <select v-model="service.category" :id="'serviceCategory' + service._id" class="form-control">
                      <option value="Women">Women</option>
                      <option value="Men">Men</option>
                      <option value="Youth">Youth</option>
                      <option value="Additional">Additional Services</option>
                    </select>
                  </div>
                  <div class="form-group">
                    <button @click="removeService(service._id)" type="button" class="btn btn-danger">Remove</button>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-lg-6">
                  <div class="form-group">
                    <button @click="addService" type="button" class="btn btn-primary btn-icon-split">
                      <span class="icon text-white-50">
                        <i class="fas fa-plus"></i>
                      </span>
                      <span class="text">Add Service</span>
                    </button>
                  </div>
                </div>
                <div class="col-lg-6">
                  <div class="form-group text-right">
                    <button type="submit" class="btn btn-success btn-icon-split">
                      <span class="icon text-white-50">
                        <i class="fas fa-check"></i>
                      </span>
                      <span class="text">Save</span>
                    </button>
                    <span v-if="contentSaved" class="badge badge-pill badge-success">Changes Saved</span>
                    <span v-if="contentError" class="badge badge-pill badge-danger">Error Saving</span>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { fetchServices, createService, updateService, deleteService } from '@/utils/servicesList';

export default {
  data() {
    return {
      services: [],
      contentSaved: false,
      contentError: false
    };
  },
  computed: {
    chunkedServiceList() {
      const chunkSize = 3;
      return this.services.reduce((chunks, service, index) => {
        const chunkIndex = Math.floor(index / chunkSize);
        if (!chunks[chunkIndex]) {
          chunks[chunkIndex] = [];
        }
        chunks[chunkIndex].push(service);
        return chunks;
      }, []);
    }
  },
  created() {
    this.loadServices();
  },
  methods: {
    async loadServices() {
      try {
        this.services = await fetchServices();
      } catch (error) {
        console.error('Error loading services:', error);
      }
    },
    async saveServices() {
      try {
        for (const service of this.services) {
          if (service._id) {
            await updateService(service._id, service);
          } else {
            const newService = await createService(service);
            service._id = newService._id; // Update the service ID if newly created
          }
        }
        this.contentSaved = true;
        this.contentError = false;
      } catch (error) {
        console.error('Error saving services:', error);
        this.contentError = true;
        this.contentSaved = false;
      } finally {
        setTimeout(() => (this.contentSaved = false), 3000);
      }
    },
    addService() {
      this.services.push({
        title: '',
        price: 0,
        time: '',
        note: '',
        category: 'Women',
        deleted: false
      });
    },
    async removeService(serviceId) {
      try {
        await deleteService(serviceId);
        this.services = this.services.filter(service => service._id !== serviceId);
      } catch (error) {
        console.error('Error removing service:', error);
      }
    }
  }
};
</script>
