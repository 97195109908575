<template>
	<section class="hero d-flex align-items-center text-white">
		<div class="container">
			<div class="row align-items-center">
				<div class="col-md-6" v-html="homepageData.homepageQuote">
				</div>
				<div class="col-md-6 text-md-right">
					<img src="@/assets/images/nadya_model_front.png" alt="Nadya Model" class="img-fluid" style="max-width: 400px;">
				</div>
			</div>
		</div>
	</section>
</template>

<script>
export default {
  name: 'SiteHome',
  data() {
    return {
      homepageData: ''
    };
  },
  created() {
    this.fetchContent();
  },
  methods: {
    async fetchContent() {
      try {
        const response = await fetch('/api/content/page_home');
        const data = await response.json();
        if (data && data.content) {
          this.homepageData = JSON.parse(data.content);
        }
      } catch (error) {
        console.error('Error fetching content:', error);
        this.homepageData = '';
      }
    }
  }
}
</script>