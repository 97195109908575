import { ref } from 'vue';
import jwtDecode  from 'jwt-decode';
import { emit } from '@/utils/eventBus'; // Corrected path

// Reactive state to track login status and admin status
const isAuthenticated = ref(false);
const isAdmin = ref(false);
const firstName = ref('');
const lastName = ref('');

function checkTokenExpiration(token) {
  if (!token) return false;

  try {
    const decoded = jwtDecode (token);
    const currentTime = Date.now() / 1000;
    return decoded.exp > currentTime;
  } catch (error) {
    console.error('Error decoding token', error);
    return false;
  }
}

function initializeAuthState() {
  const token = localStorage.getItem('token');

  if (token && checkTokenExpiration(token)) {
    isAuthenticated.value = true;
    const decoded = jwtDecode (token);
    isAdmin.value = decoded.isAdministrator;
    firstName.value = decoded.firstName;
    lastName.value = decoded.lastName;
  } else {
    logout();
  }
}

const login = (token) => {
  localStorage.setItem('token', token);
  initializeAuthState();
};

const logout = () => {
  emit('logout'); // Emit the logout event
  localStorage.removeItem('token');
  isAuthenticated.value = false;
  isAdmin.value = false;
  firstName.value = '';
  lastName.value = '';
};

initializeAuthState();

export { 
  isAuthenticated, 
  isAdmin, 
  firstName, 
  lastName, 
  login,
  logout,
};
