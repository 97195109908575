<template>
  <div class="modal fade" id="customModal" tabindex="-1" role="dialog" aria-labelledby="modalLabel" aria-hidden="true">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="modalLabel">
            <slot name="header">Default Header</slot>
          </h5>
          <button class="close" type="button" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <slot name="body">Default Body</slot>
        </div>
        <div class="modal-footer">
          <button class="btn btn-secondary" type="button" data-dismiss="modal">Cancel</button>
          <button class="btn btn-primary" @click="handleSubmit">
            <slot name="submitButton">Submit</slot>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AdminModal',
  props: {
    onSubmit: {
      type: Function,
      default: () => {}
    }
  },
  methods: {
    handleSubmit() {
      this.onSubmit();
      // eslint-disable-next-line no-undef
      $('#customModal').modal('hide');
    }
  }
};
</script>
