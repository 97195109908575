<template>
  <section class="hero d-flex align-items-center text-white">
    <div class="container">
      <div class="row">
        <div class="col-12 text-center">
          <h1><span class="highlight">Contact Us</span></h1>
        </div>
      </div>
    </div>
  </section>

  <section class="py-3 text-white">
    <div class="container">
      <div class="row">
        <div class="col-md-6">
          <p><strong>Phone:</strong> +1 (347)481-8056</p>
        </div>
        <div class="content-block col-md-6 p-4">
          <div v-if="formSubmitted" class="success-message">
            <p>Thank you for contacting us! Your message has been sent successfully.</p>
            <button @click="resetForm" class="btn btn-warning btn-block">Send Another Message</button>
          </div>

          <div v-else>
            <h2>Send us a message</h2>
            <form @submit.prevent="submitForm">
              <div class="form-row">
                <div class="form-group col-md-6">
                  <input type="text" class="form-control contact-input" id="fullName" v-model="form.fullName" placeholder="Full Name" required>
                </div>
                <div class="form-group col-md-6">
                  <input type="email" class="form-control contact-input" id="emailAddress" v-model="form.emailAddress" placeholder="Email Address" required>
                </div>
              </div>
              <div class="form-group">
                <input type="text" class="form-control contact-input" id="phone" v-model="form.phone" placeholder="Phone Number" required>
              </div>
              <div class="form-group">
                <textarea class="form-control contact-input" id="message" v-model="form.message" rows="4" placeholder="Message"></textarea>
              </div>
              <div class="col-md-6 contact-submit">
                <VueHcaptcha
                  :sitekey="hCaptchaSiteKey"
                  @verify="onCaptchaVerified"
                  @expired="onCaptchaExpired"
                  @error="onCaptchaError"
                />
                <button type="submit" class="btn btn-warning btn-block" :disabled="!captchaToken">
                  Send Message <span>&#8594;</span>
                </button>
              </div>
            </form>
            <p v-if="errorMessage" class="error-message">{{ errorMessage }}</p>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import VueHcaptcha from '@hcaptcha/vue3-hcaptcha';

export default {
  name: 'SiteContact',
  components: {
    VueHcaptcha
  },
  data() {
    return {
      form: {
        fullName: '',
        emailAddress: '',
        phone: '',
        message: ''
      },
      formSubmitted: false,
      errorMessage: '',
      captchaToken: '',
      hCaptchaSiteKey: process.env.VUE_APP_HCAPTCHA_SITE_KEY,
    };
  },
  methods: {
    async submitForm() {
      this.errorMessage = '';
      if (!this.form.fullName || !this.form.emailAddress || !this.form.phone || !this.captchaToken) {
        this.errorMessage = 'Please fill in all required fields.';
        return;
      }

      try {
        const response = await fetch(`${process.env.VUE_APP_SITE_URL}/api/send-email`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({...this.form, captchaToken: this.captchaToken}),
        });
        
        if (response.ok) {
          this.formSubmitted = true;
        } else {
          this.errorMessage = 'Failed to send message. Please try again.';
        }
      } catch (error) {
        this.errorMessage = `Error sending message: ${error.message}`;
      }
    },
    resetForm() {
      this.form = {
        fullName: '',
        emailAddress: '',
        phone: '',
        message: '',
      };
      this.formSubmitted = false;
      this.errorMessage = '';
      this.captchaToken = '';
    },
    onCaptchaVerified(token) {
      this.captchaToken = token; // Set the captcha token when verified
    },
    onCaptchaExpired() {
      this.captchaToken = ''; // Clear the captcha token when it expires
    },
    onCaptchaError() {
      this.captchaToken = ''; // Clear the captcha token on error
    },
  },
}
</script>

<style scoped>
.success-message {
  color: #e2b06e;
}

.error-message {
  color: red;
}
</style>
