const fetchAssignedBookings = async() => {
  const token = localStorage.getItem('token');
  const response = await fetch('/api/booking/assigned', {
    method: 'GET',
    headers: {
      'Authorization': token ? `Bearer ${token}` : '',
    },
  });
  if (!response.ok) {
    throw new Error('Failed to fetch assigned bookings');
  }
  return response.json();
};

const fetchUnassignedBookings = async() => {
  const token = localStorage.getItem('token');
  const response = await fetch('/api/booking/unassigned', {
    method: 'GET',
    headers: {
      'Authorization': token ? `Bearer ${token}` : '',
    },
  });
  if (!response.ok) {
    throw new Error('Failed to fetch unassigned bookings');
  }
  return response.json();
};

const fetchBooking = async (bookingId) => {
  const token = localStorage.getItem('token');
  const response = await fetch(`/api/booking/${bookingId}`, {
    method: 'GET',
    headers: {
      'Authorization': token ? `Bearer ${token}` : '',
    },
  });
  if (!response.ok) {
    throw new Error('Failed to fetch single booking');
  }
  return response.json();
};

const updateBookingDetails = async(booking) => {
  const token = localStorage.getItem('token');
  const response = await fetch(`/api/booking/update/${booking._id}`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': token ? `Bearer ${token}` : '',
    },
    body: JSON.stringify(booking),
  });
  if (!response.ok) {
    throw new Error('Failed to update booking details');
  }
  return response.json();
};

const deleteBookingRequest = async(bookingId) => {
  const token = localStorage.getItem('token');
  const response = await fetch(`/api/booking/delete/${bookingId}`, {
    method: 'PUT',
    headers: {
      'Authorization': token ? `Bearer ${token}` : '',
    },
  });
  if (!response.ok) {
    throw new Error('Failed to delete booking');
  }
  return response.json();
};

const sendBookingEmail = async(bookingId) => {
  const token = localStorage.getItem('token');
  const response = await fetch(`/api/send-email/booking/${bookingId}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': token ? `Bearer ${token}` : '',
    },
  });
  if (!response.ok) {
    const errorData = await response.json();
    throw new Error(`Error sending email: ${errorData.error || 'Unknown error'}`);
  }
  return response.json();
};


export { 
  fetchAssignedBookings,
  fetchUnassignedBookings, 
  fetchBooking,
  updateBookingDetails,
  deleteBookingRequest,
  sendBookingEmail,
};
