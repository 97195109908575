<template>
  <section class="hero d-flex align-items-center text-white text-center">
    <div class="container">
      <h1><span class="highlight">Our Services</span></h1>
    </div>
  </section>
	
  <section class="py-3">
    <div class="container">
      <div class="row justify-content-center">
        <div class="content-block col-md-10 p-4" v-if="servicesData.servicesDescription" v-html="servicesData.servicesDescription">
        </div>
      </div>
    </div>
  </section>
	
  <section class="hero d-flex align-items-center text-white text-center">
    <div class="container">
      <h2>Payments</h2>
    </div>
  </section>
	
  <section class="py-3">
    <div class="container">
      <div class="row justify-content-center">
        <div class="content-block col-md-10 p-4" v-if="servicesData.servicesPayments" v-html="servicesData.servicesPayments">
        </div>
      </div>
    </div>
  </section>

  <section class="hero d-flex align-items-center text-white text-center py-3">
    <div class="container">
    <h2>Policy</h2>
      <p v-if="servicesData.servicesPolicySubheading" v-html="servicesData.servicesPolicySubheading"></p>
    </div>
  </section>
    
  <section class="py-3">
    <div class="container">
      <div class="row justify-content-center">
        <div class="content-block col-md-10 p-4" v-if="servicesData.servicesBookingPolicy" v-html="servicesData.servicesBookingPolicy">
        </div>
      </div>
    </div>
  </section>
	
  <section class="py-3">
    <div class="container">
      <div class="row justify-content-center">
        <div class="content-block col-md-10 p-4" v-if="servicesData.servicesCancellationPolicy" v-html="servicesData.servicesCancellationPolicy">
        </div>
      </div>
    </div>
  </section>
	
  <section class="py-3">
    <div class="container">
      <div class="row justify-content-center">
        <div class="content-block col-md-10 p-4" v-if="servicesData.servicesGeneralPolicy" v-html="servicesData.servicesGeneralPolicy">
        </div>
      </div>
    </div>
  </section>
	
  <section class="py-3">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-md-10 p-4">
          <p><strong><span class="highlight" v-if="servicesData.servicesPolicyFooter" v-html="servicesData.servicesPolicyFooter"></span></strong></p>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'SiteServices',
  data() {
    return {
      servicesData: {},
    };
  },
  created() {
    this.fetchContent();
  },
  methods: {
    async fetchContent() {
      try {
        const response = await fetch('/api/content/page_services');
        const data = await response.json();
        if (data && data.content) {
          this.servicesData = JSON.parse(data.content);
        }
      } catch (error) {
        console.error('Error fetching content:', error);
        this.servicesData = '';
      }
    }
  },
  computed: {
    chunkedServiceList() {
      const chunks = [];
      
      if(this.servicesData.serviceList !== null) {
        const chunkSize = 3;
        for (let i = 0; i < this.servicesData.serviceList.length; i += chunkSize) {
          chunks.push(this.servicesData.serviceList.slice(i, i + chunkSize));
        }
      }
      return chunks;
    },
  }
  /* Services list that we are hiding for now
  <section class="hero d-flex align-items-center text-white text-center py-3">
    <div class="container">
      <h2>Pricing</h2>
    </div>
  </section>
  
  <section>
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-md-10" v-if="servicesData.serviceList">
          <div v-for="(chunk, chunkIndex) in chunkedServiceList" :key="chunkIndex" class="card-deck mb-3 text-center">
            <div v-for="(service, index) in chunk" :key="index" class="card border-secondary bg-dark mb-4 box-shadow text-white">
              <div class="card-body">
                <h5 class="card-title" v-if="service.title">{{ service.title }}</h5>
                <div class="card-subtitle pricing-card-title" v-if="service.price">{{ service.price }}</div>
                <p class="card-text" v-if="service.time">{{ service.time }}</p>
                <p class="card-text" v-if="service.noteText">{{ service.noteText }}</p>
              </div>
              <div class="card-footer">
                <router-link to="/bookanappointment" class="btn btn-lg btn-block btn-light">Book it</router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  */
}
</script>