import { ref } from 'vue';

const events = ref({});

export function emit(event, payload) {
  if (!events.value[event]) {
    events.value[event] = [];
  }
  events.value[event].forEach(callback => callback(payload));
}

export function on(event, callback) {
  if (!events.value[event]) {
    events.value[event] = [];
  }
  events.value[event].push(callback);
}

export function off(event, callback) {
  if (!events.value[event]) return;
  events.value[event] = events.value[event].filter(cb => cb !== callback);
}
