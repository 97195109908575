<template>
  <div class="container-fluid">
    <div class="d-sm-flex align-items-center justify-content-between mb-4">
      <h1 class="h3 mb-0 text-gray-800">About Page</h1>
    </div>
    
    <div class="row">
      <div class="col-lg-8">
        <div class="card shadow mb-4">
          <a href="#collapseNadezdaBio" class="d-block card-header py-3" data-toggle="collapse" role="button" aria-expanded="true" aria-controls="collapseNadezdaBio">
            <h6 class="m-0 font-weight-bold text-primary">Nadezda Vlasova</h6>
          </a>
          <div class="card-body collapse" id="collapseNadezdaBio">
            <form @submit.prevent="saveContent">
              <div class="form-group">
                <label for="nadezdaDisplayName">Display Name</label>
                <input v-model="pageData.nadezdaName" id="nadezdaDisplayName" class="form-control" type="text" placeholder="Display Name" />
              </div>
              <div class="form-group">
                <label for="nadezdaSubtitle">Sub Title</label>
                <input v-model="pageData.nadezdaSubtitle" id="nadezdaSubtitle" class="form-control" type="text" placeholder="Sub Title" />
              </div>
              <div class="form-group">
                <label for="nadezdaDescription">Description</label>
                <input v-model="pageData.nadezdaDescription" id="nadezdaDescription" class="form-control" type="text" placeholder="Description" />
              </div>
              <div class="form-group">
                <label>Bio</label>
                <Editor
                  v-model="pageData.nadezdaBio" 
                  :api-key="tinymceApiKey" 
                  :init="{
                    height: 350,
                    menubar: false,
                    plugins: 'link code',
                    toolbar: 'undo redo | bold italic forecolor | alignleft aligncenter alignright alignjustify | removeformat | link code',
                    color_map_foreground: [
                      '000000', 'Black',
                      'FFFFFF', 'White',
                      'e2b06e', 'Gold',
                    ],
                    content_style: 'body { background-color: #878e95; }',
                  }"
                />
                <div class="form-group">
                  <div class="text-right">
                    <br />
                    <button type="submit" class="btn btn-success btn-icon-split">
                      <span class="icon text-white-50">
                        <i class="fas fa-check"></i>
                      </span>
                      <span class="text">Save</span>
                    </button>
                    <br />
                    <span v-if="contentSaved" class="badge badge-pill badge-success" style="font-size: 0.8rem;">Changes Saved</span>
                    <span v-if="contentError" class="badge badge-pill badge-danger" style="font-size: 0.8rem;">Error Saving</span>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-8">
        <div class="card shadow mb-4">
          <a href="#collapseValeriiaBio" class="d-block card-header py-3" data-toggle="collapse" role="button" aria-expanded="true" aria-controls="collapseValeriiaBio">
            <h6 class="m-0 font-weight-bold text-primary">Valeriia Belezerova</h6>
          </a>
          <div class="card-body collapse" id="collapseValeriiaBio">
            <form @submit.prevent="saveContent">
              <div class="form-group">
                <label for="valeriiaDisplayName">Display Name</label>
                <input v-model="pageData.valeriiaName" id="valeriiaDisplayName" class="form-control" type="text" placeholder="Display Name" />
              </div>
              <div class="form-group">
                <label for="valeriiaSubtitle">Sub Title</label>
                <input v-model="pageData.valeriiaSubtitle" id="valeriiaSubtitle" class="form-control" type="text" placeholder="Sub Title" />
              </div>
              <div class="form-group">
                <label for="valeriiaDescription">Description</label>
                <input v-model="pageData.valeriiaDescription" id="valeriiaDescription" class="form-control" type="text" placeholder="Description" />
              </div>
              <div class="form-group">
                <label>Bio</label>
                <Editor
                  v-model="pageData.valeriiaBio" 
                  :api-key="tinymceApiKey" 
                  :init="{
                    height: 350,
                    menubar: false,
                    plugins: 'link code',
                    toolbar: 'undo redo | bold italic forecolor | alignleft aligncenter alignright alignjustify | removeformat | link code',
                    color_map_foreground: [
                      '000000', 'Black',
                      'FFFFFF', 'White',
                      'e2b06e', 'Gold',
                    ],
                    content_style: 'body { background-color: #878e95; }',
                  }"
                />
                <div class="form-group">
                  <div class="text-right">
                    <br />
                    <button type="submit" class="btn btn-success btn-icon-split">
                      <span class="icon text-white-50">
                        <i class="fas fa-check"></i>
                      </span>
                      <span class="text">Save</span>
                    </button>
                    <br />
                    <span v-if="contentSaved" class="badge badge-pill badge-success" style="font-size: 0.8rem;">Changes Saved</span>
                    <span v-if="contentError" class="badge badge-pill badge-danger" style="font-size: 0.8rem;">Error Saving</span>
                  </div>
                </div>                  
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-8">
        <div class="card shadow mb-4">
          <a href="#collapseMariaBio" class="d-block card-header py-3" data-toggle="collapse" role="button" aria-expanded="true" aria-controls="collapseMariaBio">
            <h6 class="m-0 font-weight-bold text-primary">Maria Oblakova</h6>
          </a>
          <div class="card-body collapse" id="collapseMariaBio">
            <form @submit.prevent="saveContent">
              <div class="form-group">
                <label for="mariaDisplayName">Display Name</label>
                <input v-model="pageData.mariaName" id="mariaDisplayName" class="form-control" type="text" placeholder="Display Name" />
              </div>
              <div class="form-group">
                <label for="mariaSubtitle">Sub Title</label>
                <input v-model="pageData.mariaSubtitle" id="mariaSubtitle" class="form-control" type="text" placeholder="Sub Title" />
              </div>
              <div class="form-group">
                <label for="mariaDescription">Description</label>
                <input v-model="pageData.mariaDescription" id="mariaDescription" class="form-control" type="text" placeholder="Description" />
              </div>
              <div class="form-group">
                <label>Bio</label>
                <Editor
                  v-model="pageData.mariaBio" 
                  :api-key="tinymceApiKey" 
                  :init="{
                    height: 350,
                    menubar: false,
                    plugins: 'link code',
                    toolbar: 'undo redo | bold italic forecolor | alignleft aligncenter alignright alignjustify | removeformat | link code',
                    color_map_foreground: [
                      '000000', 'Black',
                      'FFFFFF', 'White',
                      'e2b06e', 'Gold',
                    ],
                    content_style: 'body { background-color: #878e95; }',
                  }"
                />
                <div class="form-group">
                  <div class="text-right">
                    <br />
                    <button type="submit" class="btn btn-success btn-icon-split">
                      <span class="icon text-white-50">
                        <i class="fas fa-check"></i>
                      </span>
                      <span class="text">Save</span>
                    </button>
                    <br />
                    <span v-if="contentSaved" class="badge badge-pill badge-success" style="font-size: 0.8rem;">Changes Saved</span>
                    <span v-if="contentError" class="badge badge-pill badge-danger" style="font-size: 0.8rem;">Error Saving</span>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Editor from '@tinymce/tinymce-vue';

export default {
  name: 'AdminPagesAbout',
  components: {
    Editor
  },
  data() {
    return {
      pageData: '',
      tinymceApiKey: process.env.VUE_APP_TINYMCE_API_KEY,
      contentSaved: false,
      contentError: false,
    };
  },
  created() {
    this.fetchContent();
  },
  methods: {
    async fetchContent() {
      try {
        const response = await fetch('/api/content/page_about');
        const data = await response.json();
        
        if(data.content) {
          
          this.pageData = JSON.parse(data.content);
        }
      } catch (error) {
        console.error('Error fetching content:', error);
      }
    },
    async saveContent() {
      const token = localStorage.getItem('token');
      try {
        const response = await fetch('/api/content/page_about', {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': token ? `Bearer ${token}` : '',
          },
          body: JSON.stringify({ content: this.pageData })
        });

        if (response.ok) {
          this.contentSaved = true;
          this.contentError = false;
          
          setTimeout(() => {
            this.contentSaved = false;
          }, 5000);
        } else {
          //const errorData = await response.json();
          //alert(`Error saving content: ${errorData.error}`);
          this.contentSaved = false;
          this.contentError = true;
          
          setTimeout(() => {
            this.contentError = false;
          }, 5000);
        }
      } catch (error) {
        console.error('Error saving content:', error);
        this.contentSaved = false;
        this.contentError = true;
        
        setTimeout(() => {
          this.contentError = false;
        }, 5000);
      }
    }
  }
}
</script>
