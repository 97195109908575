<template>
<!-- Footer -->
<footer class="sticky-footer bg-white">
	<div class="container my-auto">
		<div class="copyright text-center my-auto">
			<span>&copy; 2024 ENVY Image Studio</span>
		</div>
	</div>
</footer>
<!-- End of Footer -->
</template>

<script>
export default {
  name: 'AdminFooter'
}
</script>