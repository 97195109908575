<template>
<div class="row justify-content-center">

	<div class="col-lg-6 col-md-6">

		<div class="card o-hidden border-0 shadow-lg my-5">
			<div class="card-body p-0">
				<!-- Nested Row within Card Body -->
				<div class="row text-center align-items-center">
					<div class="col-lg-6">
            <img src="@/assets/admin/images/logo_admin.png" alt="ENVY Image Studio Logo" id="nav-logo">
          </div>
					<div class="col-lg-6">
						<div class="p-5">
							<div class="text-center">
								<h1 class="h4 text-gray-900 mb-4">Welcome Back!</h1>
							</div>
							<form class="user" @submit.prevent="login">
								<div class="form-group">
									<input v-model="form.email" type="email" class="form-control form-control-user"
										id="email" aria-describedby="emailHelp"
										placeholder="Email Address" required>
								</div>
								<div class="form-group">
									<input v-model="form.password" type="password" class="form-control form-control-user"
										id="password" autocomplete="current-password" placeholder="Password" required>
								</div>

								<button type="submit" class="btn btn-primary btn-user btn-block">Login</button>
								<p v-if="errorMessage">{{ errorMessage }}</p>
							</form>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
</template>

<script>
import { login } from '@/utils/auth';
import { isAuthenticated } from '@/utils/auth';

export default {
  name: 'AdminLogin',
  data() {
    return {
      form: {
        email: '',
        password: '',
      },
      errorMessage: ''
    };
  },
  methods: {
    async login() {

      this.errorMessage = '';

      if (!this.form.email || !this.form.password) {
        this.errorMessage = 'Please fill in all required fields.';
        return;
      }

      try {
        const response = await fetch('/api/auth/login', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            email: this.form.email,
            password: this.form.password
          })
        });

        if (!response.ok) {
          // Handle non-200 HTTP responses
          const errorData = await response.json();
          this.errorMessage = errorData.error || 'Login failed';
          return;
        }

        const data = await response.json();
        login(data.token);
        this.$router.push('/admin/home'); // Navigate to the admin panel after login
      } catch (error) {
        this.errorMessage = 'An error occurred during login';
        console.error('Login error:', error);
      }
    }
  },
  mounted() {
    if (isAuthenticated.value) {
      this.$router.push('/admin/home');
    }
  }
};
</script>