<template>
  <div class="container-fluid">
    <h1>Welcome back {{ userFirstName }} {{ userLastName }}</h1>
  </div>
</template>

<script>
import { computed } from 'vue';
import { firstName, lastName } from '@/utils/auth';

export default {
  name: 'AdminHome',
  setup() {
    const userFirstName = computed(() => firstName.value);
    const userLastName = computed(() => lastName.value);

    return {
      userFirstName,
      userLastName
    };
  }
};
</script>