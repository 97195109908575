const fetchStates = async () => {
  return [
    'Alabama', 'Alaska', 'Arizona', 'Arkansas', 'California', 'Colorado', 'Connecticut',
    'Delaware', 'Florida', 'Georgia', 'Hawaii', 'Idaho', 'Illinois', 'Indiana', 'Iowa',
    'Kansas', 'Kentucky', 'Louisiana', 'Maine', 'Maryland', 'Massachusetts', 'Michigan',
    'Minnesota', 'Mississippi', 'Missouri', 'Montana', 'Nebraska', 'Nevada', 'New Hampshire',
    'New Jersey', 'New Mexico', 'New York', 'North Carolina', 'North Dakota', 'Ohio', 'Oklahoma',
    'Oregon', 'Pennsylvania', 'Rhode Island', 'South Carolina', 'South Dakota', 'Tennessee',
    'Texas', 'Utah', 'Vermont', 'Virginia', 'Washington', 'West Virginia', 'Wisconsin', 'Wyoming'
  ];
}

/*const fetchCountries = async () => {
  return [
    'United States', 'Canada', 'United Kingdom', 'Australia', 'France', 'Germany', 'Italy',
    'Spain', 'China', 'Japan', 'India', 'Mexico', 'Brazil', 'Russia', 'South Africa', 'South Korea'
  ];
}*/

const fetchUpcomingEvents = async () => {
  const response = await fetch('/api/events/upcoming');
  if (!response.ok) {
    throw new Error('Failed to fetch upcoming events');
  }
  return response.json();
};

const fetchPastEvents = async () => {
  const response = await fetch('/api/events/past');
  if (!response.ok) {
    throw new Error('Failed to fetch past events');
  }
  return response.json();
};

const createEvent = async (event) => {
  const token = localStorage.getItem('token');
  const response = await fetch('/api/events/create', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': token ? `Bearer ${token}` : ''
    },
    body: JSON.stringify(event)
  });
  if (!response.ok) {
    throw new Error('Failed to create event');
  }
  return response.json();
};

const updateEventShowStatus = async (eventId, showEvent) => {
  const token = localStorage.getItem('token');
  const response = await fetch(`/api/events/${eventId}/show`, {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': token ? `Bearer ${token}` : ''
    },
    body: JSON.stringify({ showEvent })
  });
  if (!response.ok) {
    throw new Error('Failed to update showEvent status');
  }
  return response.json();
};

const fetchCalendarSettings = async () => {
  const response = await fetch('/api/events/settings');
  if (!response.ok) {
    throw new Error('Failed to fetch calendar settings');
  }
  return response.json();
};

const saveCalendarSettings = async (settings, onSuccess, onError) => {
  const token = localStorage.getItem('token');

  try {
    const response = await fetch('/api/events/settings', {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token ? `Bearer ${token}` : ''
      },
      body: JSON.stringify(settings)
    });
    
    if (!response.ok) {
      if (onError) onError();
      throw new Error('Failed to save calendar settings');
    }
    
    if (onSuccess) onSuccess();
    return response.json();
  } catch (error) {
    if (onError) onError();
    throw error;
  }
};

const deleteEventById = async (eventId, onSuccess, onError) => {
  const token = localStorage.getItem('token');
  
  try {
    const response = await fetch(`/api/events/${eventId}/delete`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token ? `Bearer ${token}` : ''
      },
    });

    if (!response.ok) {
      throw new Error('Failed to delete event');
    }
    
    if (onSuccess) onSuccess();
    return response.json();
  } catch (error) {
    if (onError) onError();
    throw error;
  }
};


const formatDate = (dateString) => {
  const options = { month: 'long', day: 'numeric' };
  const date = new Date(dateString);
  return new Intl.DateTimeFormat('en-US', options).format(date);
};

const formatDateInput = (date) => {
  if (!date) return '';
  const d = new Date(date);
  return d.toISOString().slice(0, 16);
};

export {
  fetchStates,
  fetchUpcomingEvents,
  fetchPastEvents,
  createEvent,
  fetchCalendarSettings,
  saveCalendarSettings,
  updateEventShowStatus,
  deleteEventById,
  formatDate,
  formatDateInput
};
