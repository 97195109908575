<template>
  <section class="hero text-white text-center">
    <div class="container">
      <h1><span class="highlight">Gallery</span></h1>
    </div>
  </section>
  <section class="gallery py-3">
    <div class="container">
      <div class="row">
        <div class="col-md-2">
          <div class="nav flex-column nav-pills" id="v-pills-tab" role="tablist" aria-orientation="vertical">
            <a class="nav-link active" id="v-pills-bridal-tab" data-toggle="pill" href="#v-pills-bridal" role="tab" aria-controls="v-pills-bridal" aria-selected="true">Bridal</a>
            <a class="nav-link" id="v-pills-editorial-tab" data-toggle="pill" href="#v-pills-editorial" role="tab" aria-controls="v-pills-editorial" aria-selected="false">Editorial</a>
            <a class="nav-link" id="v-pills-ballroom-tab" data-toggle="pill" href="#v-pills-ballroom" role="tab" aria-controls="v-pills-ballroom" aria-selected="false">Ballroom</a>
          </div>
        </div>
        <div class="col-md-10">
          <div class="tab-content" id="v-pills-tabContent">
            <div class="tab-pane fade show active" id="v-pills-bridal" role="tabpanel" aria-labelledby="v-pills-bridal-tab">
              <Fancybox class="container" group="gallery1">
                <div v-for="(row, rowIndex) in chunkedGalleryBridal" :key="rowIndex" class="row justify-content-md-center">
                  <div v-for="(photo, colIndex) in row" :key="colIndex" class="col-md-3">
                    <a v-if="photo && photo.fullSizeUrl" data-fancybox="gallery1" :href="photo.fullSizeUrl">
                      <img :src="photo.thumbnailUrl" class="rounded" />
                    </a>
                    <div v-else>
                      <p>Image not available</p>
                    </div>
                  </div>
                </div>
              </Fancybox>
            </div>
            <div class="tab-pane fade" id="v-pills-editorial" role="tabpanel" aria-labelledby="v-pills-editorial-tab">
              <Fancybox class="container" group="gallery2">
                <div v-for="(row, rowIndex) in chunkedGalleryEditorial" :key="rowIndex" class="row justify-content-md-center">
                  <div v-for="(photo, colIndex) in row" :key="colIndex" class="col-md-3">
                    <a v-if="photo && photo.fullSizeUrl" data-fancybox="gallery2" :href="photo.fullSizeUrl">
                      <img :src="photo.thumbnailUrl" class="rounded" />
                    </a>
                    <div v-else>
                      <p>Image not available</p>
                    </div>
                  </div>
                </div>
              </Fancybox>
            </div>
            <div class="tab-pane fade" id="v-pills-ballroom" role="tabpanel" aria-labelledby="v-pills-ballroom-tab">
              <Fancybox class="container" group="gallery3">
                <div v-for="(row, rowIndex) in chunkedGalleryBallroom" :key="rowIndex" class="row justify-content-md-center">
                  <div v-for="(photo, colIndex) in row" :key="colIndex" class="col-md-3">
                    <a v-if="photo && photo.fullSizeUrl" data-fancybox="gallery3" :href="photo.fullSizeUrl">
                      <img :src="photo.thumbnailUrl" class="rounded" />
                    </a>
                    <div v-else>
                      <p>Image not available</p>
                    </div>
                  </div>
                </div>
              </Fancybox>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import Fancybox from './ComponentGallery.vue';

export default {
  name: 'SiteGallery',
  components: {
    Fancybox,
  },
  data() {
    return {
      galleryBridal: [],
      galleryEditorial: [],
      galleryBallroom: [],
    };
  },
  computed: {
    chunkedGalleryBridal() {
      return this.chunkArray(this.galleryBridal, 4);
    },
    chunkedGalleryEditorial() {
      return this.chunkArray(this.galleryEditorial, 4);
    },
    chunkedGalleryBallroom() {
      return this.chunkArray(this.galleryBallroom, 4);
    }
  },
  created() {
    this.fetchGalleryData();
  },
  methods: {
    chunkArray(array, chunkSize) {
      const chunks = [];
      for (let i = 0; i < array.length; i += chunkSize) {
        chunks.push(array.slice(i, i + chunkSize));
      }
      return chunks;
    },
    async fetchGalleryData() {
      try {
        const response = await fetch('/api/content/page_gallery');
        const data = await response.json();
        
        if (data && data.content) {
          const parsedData = JSON.parse(data.content);
          this.galleryBridal = parsedData.bridal ? parsedData.bridal.images : [];
          this.galleryEditorial = parsedData.editorial ? parsedData.editorial.images : [];
          this.galleryBallroom = parsedData.ballroom ? parsedData.ballroom.images : [];
        }
      } catch (error) {
        console.error('Error fetching gallery data:', error);
      }
    }
  }
};
</script>

<style>
.fancybox__backdrop::after {
  content: '';
  position: absolute;
  width: 10%;
  height: 10%;
  filter: blur(2px);
  left: 50%;
  top: 50%;
  transform: scale(11);
  opacity: 0.3;
  background-image: var(--bg-image);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}

.fancybox__toolbar {
  padding: 16px;
}

.fancybox__toolbar,
.fancybox__nav {
  --f-button-border-radius: 50%;
  --f-button-bg: rgb(91 78 76 / 64%);
  --f-button-hover-bg: rgb(91 78 76 / 74%);
  --f-button-active-bg: rgb(91 78 76 / 84%);
}

.fancybox__nav {
  --f-button-svg-width: 22px;
  --f-button-svg-height: 22px;
}

.fancybox__thumbs.is-classic {
  --f-thumb-width: 48px;
  --f-thumb-height: 48px;
  --f-thumb-gap: 16px;

  --f-thumb-border-radius: 6px;
  --f-thumb-outline: 0;
}

.gallery .row+.row {
    margin-top: 1rem;
}

.nav-pills .nav-link {
  color: #fff;
  font-size: 1.3rem;
}

.nav-pills .nav-link.active {
  background-color: #6c757d38 !important;
  color: #E2B06E;
}
</style>
