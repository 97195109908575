<template>
	<AdminLeftNav />
</template>

<script>
import AdminLeftNav from './AdminLeftNav.vue';

export default {
  name: 'AdminHeader',
  components: {
    AdminLeftNav,
  },
}
</script>