const fetchUsers = async(isAdmin) => {
  const token = localStorage.getItem('token');
  const response = await fetch(`/api/auth/users?isAdmin=${isAdmin}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': token ? `Bearer ${token}` : '',
    },
  });
  if (!response.ok) {
    throw new Error('Failed to fetch users');
  }
  return response.json();
};

export { 
  fetchUsers,
};
