<template>
  <div class="container-fluid">
    <div class="d-sm-flex align-items-center justify-content-between mb-4">
      <h1 class="h3 mb-0 text-gray-800">Gallery Page</h1>
    </div>

    <div class="row">
      <div class="col-lg-8">
        <!-- Bridal Gallery -->
        <div class="card shadow mb-4">
          <a href="#collapseGalleryBridal" class="d-block card-header py-3" data-toggle="collapse" role="button" aria-expanded="true" aria-controls="collapseGalleryBridal">
            <h6 class="m-0 font-weight-bold text-primary">Bridal Gallery</h6>
          </a>
          <div class="card-body collapse" id="collapseGalleryBridal">
            <form>
              <div class="alert alert-primary" role="alert">
                You must attach a full size image and a thumbnail image in order to add it to the gallery.
              </div>
              <div class="form-group">
                <div class="custom-file">
                  <input type="file" ref="bridalFullSizeInput" @change="handleFileUpload('bridal', 'fullSize', $event)" :disabled="uploadDisabled.bridal.fullSize" id="bridalFullSizeFile">
                  <label class="custom-file-label" for="bridalFullSizeFile">{{ bridalFullSizeLabel }}</label>
                </div>
              </div>
              <div class="form-group">
                <div class="custom-file">
                  <input type="file" ref="bridalThumbnailInput" @change="handleFileUpload('bridal', 'thumbnail', $event)" :disabled="uploadDisabled.bridal.thumbnail" id="bridalThumbnailFile">
                  <label class="custom-file-label" for="bridalThumbnailFile">{{ bridalThumbnailLabel }}</label>
                </div>
              </div>
              <div class="form-group">
                <div class="text-right">
                  <span v-if="contentSaved" class="badge badge-pill badge-success" style="font-size: 0.8rem;">Changes Saved</span>
                  <span v-if="contentError" class="badge badge-pill badge-danger" style="font-size: 0.8rem;">Error Saving</span>
                </div>
              </div>
              <div class="form-group">
                <label>Bridal Images</label>
                <div class="image-list">
                  <div v-for="(image, index) in pageData.bridal.images" :key="index" class="image-item">
                    <img :src="'..' + image.thumbnailUrl" :alt="'Thumbnail ' + (index + 1)" class="img-thumbnail">
                    <button @click="deleteImage('bridal', index)" type="button" class="btn btn-danger btn-sm delete-button">Delete</button>
                    <div class="button-container">
                      <button @click="moveImage('bridal', index, -1)" type="button" class="btn btn-secondary btn-sm" :disabled="index === 0">←</button>
                      <button @click="moveImage('bridal', index, 1)" type="button" class="btn btn-secondary btn-sm" :disabled="index === pageData.bridal.images.length - 1">→</button>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>

        <!-- Editorial Gallery -->
        <div class="card shadow mb-4">
          <a href="#collapseGalleryEditorial" class="d-block card-header py-3" data-toggle="collapse" role="button" aria-expanded="true" aria-controls="collapseGalleryEditorial">
            <h6 class="m-0 font-weight-bold text-primary">Editorial Gallery</h6>
          </a>
          <div class="card-body collapse" id="collapseGalleryEditorial">
            <form>
              <div class="alert alert-primary" role="alert">
                You must attach a full size image and a thumbnail image in order to add it to the gallery.
              </div>
              <div class="form-group">
                <div class="custom-file">
                  <input type="file" ref="editorialFullSizeInput" @change="handleFileUpload('editorial', 'fullSize', $event)" :disabled="uploadDisabled.editorial.fullSize" id="editorialFullSizeFile">
                  <label class="custom-file-label" for="editorialFullSizeFile">{{ editorialFullSizeLabel }}</label>
                </div>
              </div>
              <div class="form-group">
                <div class="custom-file">
                  <input type="file" ref="editorialThumbnailInput" @change="handleFileUpload('editorial', 'thumbnail', $event)" :disabled="uploadDisabled.editorial.thumbnail" id="editorialThumbnailFile">
                  <label class="custom-file-label" for="editorialThumbnailFile">{{ editorialThumbnailLabel }}</label>
                </div>
              </div>
              <div class="form-group">
                <div class="text-right">
                  <span v-if="contentSaved" class="badge badge-pill badge-success" style="font-size: 0.8rem;">Changes Saved</span>
                  <span v-if="contentError" class="badge badge-pill badge-danger" style="font-size: 0.8rem;">Error Saving</span>
                </div>
              </div>
              <div class="form-group">
                <label>Editorial Images</label>
                <div class="image-list">
                  <div v-for="(image, index) in pageData.editorial.images" :key="index" class="image-item">
                    <img :src="'..' + image.thumbnailUrl" :alt="'Thumbnail ' + (index + 1)" class="img-thumbnail">
                    <button @click="deleteImage('editorial', index)" type="button" class="btn btn-danger btn-sm delete-button">Delete</button>
                    <div class="button-container">
                      <button @click="moveImage('editorial', index, -1)" type="button" class="btn btn-secondary btn-sm" :disabled="index === 0">←</button>
                      <button @click="moveImage('editorial', index, 1)" type="button" class="btn btn-secondary btn-sm" :disabled="index === pageData.editorial.images.length - 1">→</button>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>

        <!-- Ballroom Gallery -->
        <div class="card shadow mb-4">
          <a href="#collapseGalleryBallroom" class="d-block card-header py-3" data-toggle="collapse" role="button" aria-expanded="true" aria-controls="collapseGalleryBallroom">
            <h6 class="m-0 font-weight-bold text-primary">Ballroom Gallery</h6>
          </a>
          <div class="card-body collapse" id="collapseGalleryBallroom">
            <form>
              <div class="alert alert-primary" role="alert">
                You must attach a full size image and a thumbnail image in order to add it to the gallery.
              </div>
              <div class="form-group">
                <div class="custom-file">
                  <input type="file" ref="ballroomFullSizeInput" @change="handleFileUpload('ballroom', 'fullSize', $event)" :disabled="uploadDisabled.ballroom.fullSize" :class="{ 'd-none': uploadDisabled.ballroom.fullSize}" id="ballroomFullSizeFile">
                  <span v-if="uploadDisabled.ballroom.fullSize" class="btn btn-success btn-circle btn-sm">
                      <i class="fas fa-check"></i>
                  </span>
                  <label class="custom-file-label" for="ballroomFullSizeFile">{{ ballroomFullSizeLabel }}</label>
                </div>
              </div>
              <div class="form-group">
                <div class="custom-file">
                  <input type="file" ref="ballroomThumbnailInput" @change="handleFileUpload('ballroom', 'thumbnail', $event)" :disabled="uploadDisabled.ballroom.thumbnail" id="ballroomThumbnailFile">
                  <label class="custom-file-label" for="ballroomThumbnailFile">{{ ballroomThumbnailLabel }}</label>
                </div>
              </div>
              <div class="form-group">
                <div class="text-right">
                  <span v-if="contentSaved" class="badge badge-pill badge-success" style="font-size: 0.8rem;">Changes Saved</span>
                  <span v-if="contentError" class="badge badge-pill badge-danger" style="font-size: 0.8rem;">Error Saving</span>
                </div>
              </div>
              <div class="form-group">
                <label>Ballroom Images</label>
                <div class="image-list">
                  <div v-for="(image, index) in pageData.ballroom.images" :key="index" class="image-item">
                    <img :src="'..' + image.thumbnailUrl" :alt="'Thumbnail ' + (index + 1)" class="img-thumbnail">
                    <button @click="deleteImage('ballroom', index)" type="button" class="btn btn-danger btn-sm delete-button">Delete</button>
                    <div class="button-container">
                      <button @click="moveImage('ballroom', index, -1)" type="button" class="btn btn-secondary btn-sm" :disabled="index === 0">←</button>
                      <button @click="moveImage('ballroom', index, 1)" type="button" class="btn btn-secondary btn-sm" :disabled="index === pageData.ballroom.images.length - 1">→</button>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { v4 as uuidv4 } from 'uuid';
import { on, off } from '@/utils/eventBus';

export default {
  name: 'AdminPagesGallery',
  data() {
    return {
      pageData: {
        bridal: {
          images: []
        },
        editorial: {
          images: []
        },
        ballroom: {
          images: []
        }
      },
      uploadId: uuidv4(),
      tempImages: {},
      uploadDisabled: {
        bridal: {
          fullSize: false,
          thumbnail: false
        },
        editorial: {
          fullSize: false,
          thumbnail: false
        },
        ballroom: {
          fullSize: false,
          thumbnail: false
        }
      },
      bridalFullSizeLabel: 'Upload Full Size Image',
      bridalThumbnailLabel: 'Upload Thumbnail Image',
      editorialFullSizeLabel: 'Upload Full Size Image',
      editorialThumbnailLabel: 'Upload Thumbnail Image',
      ballroomFullSizeLabel: 'Upload Full Size Image',
      ballroomThumbnailLabel: 'Upload Thumbnail Image',
      contentSaved: false,
      contentError: false,
      token: null,
    };
  },
  created() {
    this.token = localStorage.getItem('token');
    this.fetchContent();
    window.addEventListener('beforeunload', this.handleUnload);
    on('logout', this.cleanupUploads);
  },
  beforeUnmount() {
    window.removeEventListener('beforeunload', this.handleUnload);
    off('logout', this.cleanupUploads);
  },
  beforeRouteLeave(to, from, next) {
    this.cleanupUploads().then(() => next());
  },
  methods: {
    async fetchContent() {
      try {
        const response = await fetch('/api/content/page_gallery');
        const data = await response.json();
        
        if (data && data.content) {
          this.pageData = JSON.parse(data.content);
        }
      } catch (error) {
        console.error('Error fetching content:', error);
      }
    },
    handleUnload() {
      this.cleanupUploads();
    },
    async handleFileUpload(section, type, event) {
      const files = event.target.files;
      const formData = new FormData();
      for (let i = 0; i < files.length; i++) {
        formData.append('images', files[i]);
      }

      formData.append('uploadId', this.uploadId);
      formData.append('type', type);

      try {
        const response = await fetch('/api/content/upload-gallery', {
          method: 'POST',
          headers: {
            'Authorization': this.token ? `Bearer ${this.token}` : ''
          },
          body: formData
        });

        if (response.ok) {
          const data = await response.json();

          if (data.files) {
            this.storeTempImages(section, type, data.files);
            this.uploadDisabled[section][type] = true;
            // Update the label text with the file name
            const fileName = files[0].name;
            
            if (section === 'bridal') {
              if (type === 'fullSize') {
                this.bridalFullSizeLabel = `File was uploaded: ${fileName}`;
              } else if (type === 'thumbnail') {
                this.bridalThumbnailLabel = `File was uploaded: ${fileName}`;
              }
            } else if (section === 'editorial') {
              if (type === 'fullSize') {
                this.editorialFullSizeLabel = `File was uploaded: ${fileName}`;
              } else if (type === 'thumbnail') {
                this.editorialThumbnailLabel = `File was uploaded: ${fileName}`;
              }
            } else if (section === 'ballroom') {
              if (type === 'fullSize') {
                this.ballroomFullSizeLabel = `File was uploaded: ${fileName}`;
              } else if (type === 'thumbnail') {
                this.ballroomThumbnailLabel = `File was uploaded: ${fileName}`;
              }
            }
          } else {
            console.error('No files received from server');
          }
        } else {
          console.error('Error uploading images');
        }
      } catch (error) {
        console.error('Error uploading images:', error);
      }
    },
    storeTempImages(section, type, files) {
      if (!files || files.length === 0) {
        console.error('No files received from server');
        return;
      }

      if (!this.tempImages[this.uploadId]) {
        this.tempImages[this.uploadId] = {};
      }

      const file = files[0];
      this.tempImages[this.uploadId][type === 'fullSize' ? 'fullSizeUrl' : 'thumbnailUrl'] = file[type === 'fullSize' ? 'fullSizeUrl' : 'thumbnailUrl'];

      if (this.tempImages[this.uploadId].fullSizeUrl && this.tempImages[this.uploadId].thumbnailUrl) {
        this.mergeFiles(section);
      }
    },
    async mergeFiles(section) {
      try {
        const response = await fetch('/api/content/merge-gallery', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': this.token ? `Bearer ${this.token}` : '',
          },
          body: JSON.stringify({
            pageKey: 'PAGE_GALLERY',
            uploadId: this.uploadId,
            section
          })
        });

        if (response.ok) {
          const data = await response.json();
          this.pageData[section].images.push(...data.files);
          console.log('Images merged and saved successfully');
          this.resetUploadFields(section);
          this.contentSaved = true;
          this.contentError = false;
          
          setTimeout(() => {
            this.contentSaved = false;
          }, 5000);
        } else {
          const errorData = await response.json();
          console.log(`Error merging files: ${errorData.error}`);
          this.contentSaved = false;
          this.contentError = true;
          
          setTimeout(() => {
            this.contentError = false;
          }, 5000);
        }
      } catch (error) {
        console.error('Error merging files:', error);
        console.log('An error occurred while merging the files.');
        this.contentSaved = false;
        this.contentError = true;
        
        setTimeout(() => {
          this.contentError = false;
        }, 5000);
      }
    },
    resetUploadFields(section) {
      if (section === 'bridal') {
        this.$refs.bridalFullSizeInput.value = null;
        this.$refs.bridalThumbnailInput.value = null;
        this.bridalFullSizeLabel = 'Upload Full Size Image';
        this.bridalThumbnailLabel = 'Upload Thumbnail Image';
        this.uploadDisabled.bridal.fullSize = false;
        this.uploadDisabled.bridal.thumbnail = false;
      } else if (section === 'editorial') {
        this.$refs.editorialFullSizeInput.value = null;
        this.$refs.editorialThumbnailInput.value = null;
        this.editorialFullSizeLabel = 'Upload Full Size Image';
        this.editorialThumbnailLabel = 'Upload Thumbnail Image';
        this.uploadDisabled.editorial.fullSize = false;
        this.uploadDisabled.editorial.thumbnail = false;
      } else if (section === 'ballroom') {
        this.$refs.ballroomFullSizeInput.value = null;
        this.$refs.ballroomThumbnailInput.value = null;
        this.ballroomFullSizeLabel = 'Upload Full Size Image';
        this.ballroomThumbnailLabel = 'Upload Thumbnail Image';
        this.uploadDisabled.ballroom.fullSize = false;
        this.uploadDisabled.ballroom.thumbnail = false;
      }
      this.uploadId = uuidv4();
      this.tempImages = {};
    },
    async cleanupUploads() {

      if (!this.token) {
        console.error('No token found');
        return;
      }

      const filesToDelete = [];
      Object.keys(this.tempImages).forEach(uploadId => {
        const images = this.tempImages[uploadId];
        if (images.fullSizeUrl) filesToDelete.push(images.fullSizeUrl);
        if (images.thumbnailUrl) filesToDelete.push(images.thumbnailUrl);
      });

      if (filesToDelete.length === 0) {
        console.log('No files to delete');
        return;
      }

      try {
        /*const response = */await fetch('/api/content/delete-temp-images', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${this.token}`
          },
          body: JSON.stringify({ files: filesToDelete })
        });
        
        //const responseData = await response.json();

      } catch (error) {
        console.error('Error cleaning up temporary files:', error);
      }
    },
    async deleteImage(section, index) {
      const image = this.pageData[section].images[index];
      
      try {
        const response = await fetch('/api/content/delete-image', {
          method: 'DELETE',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': this.token ? `Bearer ${this.token}` : '',
          },
          body: JSON.stringify({
            pageKey: 'PAGE_GALLERY',
            section,
            fullSizeUrl: image.fullSizeUrl,
            thumbnailUrl: image.thumbnailUrl
          })
        });

        if (response.ok) {
          this.pageData[section].images.splice(index, 1);
          console.log('Image deleted successfully!');
        } else {
          const errorData = await response.json();
          console.log(`Error deleting image: ${errorData.error}`);
        }
      } catch (error) {
        console.error('Error deleting image:', error);
        console.log('An error occurred while deleting the image.');
      }
    },
    async moveImage(section, index, direction) {
      const newIndex = index + direction;
      const image = this.pageData[section].images.splice(index, 1)[0];
      this.pageData[section].images.splice(newIndex, 0, image);

      try {
        const response = await fetch('/api/content/update-image-order', {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': this.token ? `Bearer ${this.token}` : '',
          },
          body: JSON.stringify({
            pageKey: 'PAGE_GALLERY',
            images: this.pageData[section].images,
            section
          })
        });

        if (response.ok) {
          console.log('Image order updated successfully');
        } else {
          const errorData = await response.json();
          console.log(`Error updating image order: ${errorData.error}`);
        }
      } catch (error) {
        console.error('Error updating image order:', error);
        console.log('An error occurred while updating the image order.');
      }
    }
  }
};
</script>

<style>
.image-list {
  display: flex;
  flex-wrap: wrap;
}
.image-item {
  margin: 10px;
  position: relative;
}
.image-item img {
  width: 150px;
  height: 150px;
  object-fit: cover;
}
.delete-button {
  position: absolute;
  top: 5px;
  right: 5px;
}
.button-container {
  display: flex;
  justify-content: space-between;
  margin-top: 5px;
}
</style>
