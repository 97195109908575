<template>
<!-- Topbar -->
<nav class="navbar navbar-expand navbar-light bg-white topbar mb-4 static-top shadow">

	<!-- Sidebar Toggle (Topbar) -->
	<button v-if="loggedIn" id="mobileNavbarToggle" class="navbar-toggler d-sm-none" type="button" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
		<i class="fa fa-bars"></i>
	</button>
  
	<!-- Topbar Navbar -->
	<ul class="navbar-nav ml-auto">
    <li class="nav-item dropdown no-arrow" v-if="loggedIn" id="mobileNavbar">
      <!-- Mobile Nav -->
      <div class="dropdown-menu dropdown-menu-right shadow animated--grow-in" aria-labelledby="mobileNavbarToggle">
        <!-- Nav Item - Dashboard -->
        <router-link class="dropdown-item" to="/admin/home" active-class="active">
          <i class="fas fa-fw fa-tachometer-alt fa-sm fa-fw mr-2 text-gray-400"></i>
          <span>Dashboard</span>
        </router-link>

        <!-- Divider -->
        <div v-if="isAdmin" class="dropdown-divider"></div>

        <!-- Heading -->
        <div v-if="isAdmin" class="dropdown-header">
          Pages
        </div>

        <!-- Nav Item - Home -->
        <router-link v-if="isAdmin" class="dropdown-item" to="/admin/pages_home" active-class="active">
          <i class="fas fa-home fa-sm fa-fw mr-2 text-gray-400"></i>
          <span>Home</span>
        </router-link>

        <!-- Nav Item - About -->
        <router-link v-if="isAdmin" class="dropdown-item" to="/admin/pages_about" active-class="active">
          <i class="far fa-file fa-sm fa-fw mr-2 text-gray-400"></i>
          <span>About</span>
        </router-link>
        
        <!-- Nav Item - Services -->
        <router-link v-if="isAdmin" class="dropdown-item" to="/admin/pages_services" active-class="active">
          <i class="fas fa-concierge-bell fa-sm fa-fw mr-2 text-gray-400"></i>
          <span>Services</span>
        </router-link>
        
        <!-- Nav Item - Gallery -->
        <router-link v-if="isAdmin" class="dropdown-item" to="/admin/pages_gallery" active-class="active">
          <i class="far fa-images fa-sm fa-fw mr-2 text-gray-400"></i>
          <span>Gallery</span>
        </router-link>
        
        <!-- Divider -->
        <div class="dropdown-divider"></div>

        <!-- Heading -->
        <div class="dropdown-header">
          Calendar
        </div>

        <!-- Nav Item - Manage Events -->
        <router-link v-if="isAdmin" class="dropdown-item" to="/admin/calendar_events" active-class="active">
          <i class="fas fa-city fa-sm fa-fw mr-2 text-gray-400"></i>
          <span>Manage Events</span>
        </router-link>
        
        <!-- Nav Item - Manage Bookings -->
        <router-link v-if="isAdmin" class="dropdown-item" to="/admin/calendar_bookings" active-class="active">
          <i class="fas fa-book fa-sm fa-fw mr-2 text-gray-400"></i>
          <span>Manage Bookings</span>
        </router-link>

        <!-- Nav Item - My Calendar -->
        <router-link class="dropdown-item" to="/admin/calendar_show" active-class="active">
          <i class="far fa-calendar-check fa-sm fa-fw mr-2 text-gray-400"></i>
          <span>My Calendar</span>
        </router-link>

        <!-- Divider -->
        <div v-if="isAdmin" class="dropdown-divider"></div>

        <!-- Heading -->
        <div v-if="isAdmin" class="dropdown-header">
          Site Configuration
        </div>
        
        <!-- Nav Item - Users -->
        <router-link v-if="isAdmin" class="dropdown-item" to="/admin/users" active-class="active">
          <i class="fas fa-users fa-sm fa-fw mr-2 text-gray-400"></i>
          <span>Users</span>
        </router-link>
      </div>
    </li>

		<!-- Nav Item - User Information -->
		<li class="nav-item dropdown no-arrow" v-if="loggedIn">
			<a class="nav-link dropdown-toggle" href="#" id="userDropdown" role="button"
				data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
				<span class="mr-2 d-lg-inline text-gray-600 small">{{ userFirstName }} {{ userLastName }}</span>
				<i class="fas fa-user"></i>
			</a>
			<!-- Dropdown - User Information -->
			<div class="dropdown-menu dropdown-menu-right shadow animated--grow-in"
				aria-labelledby="userDropdown">
				<a class="dropdown-item" href="#" @click.prevent="handleLogout">
					<i class="fas fa-sign-out-alt fa-sm fa-fw mr-2 text-gray-400"></i>
					Logout
				</a>
			</div>
		</li>

	</ul>

</nav>
</template>

<script>
import { computed } from 'vue';
import { useRouter } from 'vue-router';
import { isAuthenticated, isAdmin, firstName, lastName, logout } from '@/utils/auth';

export default {
  name: 'AdminTopNav',
  setup() {
    const router = useRouter();
    const loggedIn = computed(() => isAuthenticated.value);
    const userFirstName = computed(() => firstName.value);
    const userLastName = computed(() => lastName.value);
    const isAdminStatus = computed(() => isAdmin.value);

    function handleLogout() {
      logout();
      router.push('/admin/login');
    }

    return {
      loggedIn,
      handleLogout,
      userFirstName,
      userLastName,
      isAdmin: isAdminStatus
    };
  }
}
</script>

<style>
#mobileNavbarToggle {
  display: block !important;
}

#mobileNavbarToggle.collapse:not(.show) {
  display: none !important;
}

@media (min-width: 577px) {
  #mobileNavbar, #mobileNavbarToggle {
    display: none !important;
  }
  .sidebar.toggled {
    overflow: visible !important;
    width: 6.5rem !important;
  }
}

@media (max-width: 576px) {
  .topbar .dropdown .dropdown-menu {
    width: calc(100% - 1.5rem) !important;
    right: 0.75rem !important;
  }
  
  .topbar .dropdown {
    position: static !important;
  }
}
</style>