<template>
  <div class="container-fluid">
    <div class="d-sm-flex align-items-center justify-content-between mb-4">
      <h1 class="h3 mb-0 text-gray-800">Services Page</h1>
    </div>

    <div class="row">
      <div class="col-lg-8">
        <div class="card shadow mb-4">
          <a href="#collapseServicesDescription" class="d-block card-header py-3" data-toggle="collapse" role="button" aria-expanded="true" aria-controls="collapseServicesDescription">
            <h6 class="m-0 font-weight-bold text-primary">Services Description</h6>
          </a>
          <div class="card-body collapse" id="collapseServicesDescription">
            <form @submit.prevent="saveContent">
              <div class="form-group">
                <label>Description</label>
                <Editor
                  v-model="pageData.servicesDescription" 
                  :api-key="tinymceApiKey" 
                  :init="{
                    height: 350,
                    menubar: false,
                    plugins: 'link code',
                    toolbar: 'undo redo | blocks | bold italic forecolor | alignleft aligncenter alignright alignjustify | removeformat | link code',
                    color_map_foreground: [
                      '000000', 'Black',
                      'FFFFFF', 'White',
                      'e2b06e', 'Gold',
                    ],
                    block_formats: 'Header 1=h1;Header 2=h2;Header 3=h3;Header 4=h4;Paragraph=p',
                    content_style: 'body { background-color: #878e95; }',
                  }"
                />
              </div>
              <div class="form-group">
                <div class="text-right">
                  <br />
                  <button type="submit" class="btn btn-success btn-icon-split">
                    <span class="icon text-white-50">
                      <i class="fas fa-check"></i>
                    </span>
                    <span class="text">Save</span>
                  </button>
                  <br />
                  <span v-if="contentSaved" class="badge badge-pill badge-success" style="font-size: 0.8rem;">Changes Saved</span>
                  <span v-if="contentError" class="badge badge-pill badge-danger" style="font-size: 0.8rem;">Error Saving</span>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-8">
        <div class="card shadow mb-4">
          <a href="#collapsePayments" class="d-block card-header py-3" data-toggle="collapse" role="button" aria-expanded="true" aria-controls="collapsePayments">
            <h6 class="m-0 font-weight-bold text-primary">Payments</h6>
          </a>
          <div class="card-body collapse" id="collapsePayments">
            <form @submit.prevent="saveContent">
              <div class="form-group">
                <label>Payments</label>
                <Editor
                  v-model="pageData.servicesPayments" 
                  :api-key="tinymceApiKey" 
                  :init="{
                    height: 350,
                    menubar: false,
                    plugins: 'link code',
                    toolbar: 'undo redo | blocks | bold italic forecolor | alignleft aligncenter alignright alignjustify | removeformat | link code',
                    color_map_foreground: [
                      '000000', 'Black',
                      'FFFFFF', 'White',
                      'e2b06e', 'Gold',
                    ],
                    block_formats: 'Header 1=h1;Header 2=h2;Header 3=h3;Header 4=h4;Paragraph=p',
                    content_style: 'body { background-color: #878e95; }',
                  }"
                />
              </div>
              <div class="form-group">
                <div class="text-right">
                  <br />
                  <button type="submit" class="btn btn-success btn-icon-split">
                    <span class="icon text-white-50">
                      <i class="fas fa-check"></i>
                    </span>
                    <span class="text">Save</span>
                  </button>
                  <br />
                  <span v-if="contentSaved" class="badge badge-pill badge-success" style="font-size: 0.8rem;">Changes Saved</span>
                  <span v-if="contentError" class="badge badge-pill badge-danger" style="font-size: 0.8rem;">Error Saving</span>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-8">
        <div class="card shadow mb-4">
          <a href="#collapsePolicy" class="d-block card-header py-3" data-toggle="collapse" role="button" aria-expanded="true" aria-controls="collapsePolicy">
            <h6 class="m-0 font-weight-bold text-primary">Policy</h6>
          </a>
          <div class="card-body collapse" id="collapsePolicy">
            <form @submit.prevent="saveContent">
              <div class="form-group">
                <label for="servicesPolicySubheading">Policy Subheading</label>
                <input v-model="pageData.servicesPolicySubheading" id="servicesPolicySubheading" class="form-control" type="text" placeholder="Policy Subheading" />
              </div>
              <div class="form-group">
                <label>Booking Policy</label>
                <Editor
                  v-model="pageData.servicesBookingPolicy" 
                  :api-key="tinymceApiKey" 
                  :init="{
                    height: 350,
                    menubar: false,
                    plugins: 'link code',
                    toolbar: 'undo redo | blocks | bold italic forecolor | alignleft aligncenter alignright alignjustify | removeformat | link code',
                    color_map_foreground: [
                      '000000', 'Black',
                      'FFFFFF', 'White',
                      'e2b06e', 'Gold',
                    ],
                    block_formats: 'Header 1=h1;Header 2=h2;Header 3=h3;Header 4=h4;Paragraph=p',
                    content_style: 'body { background-color: #878e95; }',
                  }"
                />
              </div>
              <div class="form-group">
                <label>Cancellation Policy</label>
                <Editor
                  v-model="pageData.servicesCancellationPolicy" 
                  :api-key="tinymceApiKey" 
                  :init="{
                    height: 350,
                    menubar: false,
                    plugins: 'link code',
                    toolbar: 'undo redo | blocks | bold italic forecolor | alignleft aligncenter alignright alignjustify | removeformat | link code',
                    color_map_foreground: [
                      '000000', 'Black',
                      'FFFFFF', 'White',
                      'e2b06e', 'Gold',
                    ],
                    block_formats: 'Header 1=h1;Header 2=h2;Header 3=h3;Header 4=h4;Paragraph=p',
                    content_style: 'body { background-color: #878e95; }',
                  }"
                />
              </div>
              <div class="form-group">
                <label>General Policy</label>
                <Editor
                  v-model="pageData.servicesGeneralPolicy" 
                  :api-key="tinymceApiKey" 
                  :init="{
                    height: 350,
                    menubar: false,
                    plugins: 'link code',
                    toolbar: 'undo redo | blocks | bold italic forecolor | alignleft aligncenter alignright alignjustify | removeformat | link code',
                    color_map_foreground: [
                      '000000', 'Black',
                      'FFFFFF', 'White',
                      'e2b06e', 'Gold',
                    ],
                    block_formats: 'Header 1=h1;Header 2=h2;Header 3=h3;Header 4=h4;Paragraph=p',
                    content_style: 'body { background-color: #878e95; }',
                  }"
                />
              </div>
              <div class="form-group">
                <label for="servicesPolicyFooter">Policy Footer</label>
                <input v-model="pageData.servicesPolicyFooter" id="servicesPolicyFooter" class="form-control" type="text" placeholder="Policy Footer" />
              </div>
              <div class="form-group">
                <div class="text-right">
                  <br />
                  <button type="submit" class="btn btn-success btn-icon-split">
                    <span class="icon text-white-50">
                      <i class="fas fa-check"></i>
                    </span>
                    <span class="text">Save</span>
                  </button>
                  <br />
                  <span v-if="contentSaved" class="badge badge-pill badge-success" style="font-size: 0.8rem;">Changes Saved</span>
                  <span v-if="contentError" class="badge badge-pill badge-danger" style="font-size: 0.8rem;">Error Saving</span>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Editor from '@tinymce/tinymce-vue';

export default {
  name: 'AdminPagesServices',
  components: {
    Editor
  },
  data() {
    return {
      pageData: {
        servicesDescription: ''
      },
      tinymceApiKey: process.env.VUE_APP_TINYMCE_API_KEY,
      contentSaved: false,
      contentError: false,
    };
  },
  created() {
    this.fetchContent();
  },
  methods: {
    async fetchContent() {
      try {
        const response = await fetch('/api/content/page_services');
        const data = await response.json();
        
        if (data && data.content) {
          this.pageData = JSON.parse(data.content);
        }
      } catch (error) {
        console.error('Error fetching content:', error);
      }
    },
    async saveContent() {
      const token = localStorage.getItem('token');
      try {
        const response = await fetch('/api/content/page_services', {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': token ? `Bearer ${token}` : '',
          },
          body: JSON.stringify({ content: this.pageData })
        });

        if (response.ok) {
          this.contentSaved = true;
          this.contentError = false;
          
          setTimeout(() => {
            this.contentSaved = false;
          }, 5000);
        } 
        else {
          this.contentSaved = false;
          this.contentError = true;
          
          setTimeout(() => {
            this.contentError = false;
          }, 5000);
        }
      } 
      catch (error) {
        console.error('Error saving content:', error);
        this.contentSaved = false;
        this.contentError = true;
        
        setTimeout(() => {
          this.contentError = false;
        }, 5000);
      }
    },
  }
}
</script>
